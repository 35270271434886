import React from 'react';
import { Trans, Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';

import ReactPlayer from 'react-player';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    siteTitle: {
      fontSize: '2.6rem',
      fontWeight: '600',
      color: COLOR_WHITE,
      animation: 'textwelle 0.6s',
    },
    box: {
      minHeight: '550px',
      width: 'auto',
      margin: '0px 18% 0px 18%',
      paddingTop: '60px',
      // backgroundColor: '#344266',
    },
    boxoben: {
      minHeight: '70px',
      width: 'auto',
      backgroundColor: '#000038',
      // backgroundColor: '#344266',
    },
    boxImagesNoContent: {
      minHeight: '30px',
    },
    headImagesTextPosition: {
      display: 'inline-flex',
      maxWidth: '1440px',
      padding: '4em 1em 0em 1em',
    },
    videoWrap: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '900px',
      margin: 'auto',
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        marginLeft: '32px',
        marginRight: '32px',
      },
      '& video': {
        borderRadius: '16px',
        marginTop: '24px',
        boxShadow:
          '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  }),
);

export const IntroPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { backgroundImagePrices } = useStaticQuery(
    graphql`
      query {
        backgroundImagePrices: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );
  const pluginImage = getImage(backgroundImagePrices);

  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesNoContent} />
          </Grid>
        </Grid>
      </BgImage>

      <Box className={classes.box}>
        <Typography paragraph variant="h1">
          {t('introVideo')}
        </Typography>

        <Typography paragraph variant="body1">
          {t('introDescription')}
        </Typography>

        <Box className={classes.videoWrap}>
          <ReactPlayer
            controls
            width="100%"
            height="auto"
            url="https://space.d-simple-storage.com/static/videos/umo.mp4"
            config={{
              file: {
                tracks: [{ kind: 'captions', src: '/umo_gesamt.vtt' }],
              },
            }}
          />
        </Box>

        <Box my={4}>
          <Typography>
            <Trans t={t} i18nKey="content.umoCheckProduct" components={[<Link key="0" to="/product" />]} />
          </Typography>
        </Box>
      </Box>
    </>
  );
};
